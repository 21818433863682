import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Portfolio from "./components";
// import Navbar from "./components/Navbar";
// import Home from "./components/Home/Home";
// import About from "./components/About/About";
// import Projects from "./components/Projects/Projects";
// import Footer from "./components/Footer";
// import Resume from "./components/Resume/ResumeNew";
// import Experience from './components/Experience/Experience'
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate
// } from "react-router-dom";
// import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      <Preloader load={load}></Preloader>
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Portfolio/>
      </div>
    </React.Fragment>
   
  );
}

export default App;
/**
 * load info from a json file
 *   <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Home/>
        <Footer />
      </div>
    </Router>
 *  <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
 */